* {
  margin: 0;
  padding: 0;
  text-align: left;
}
$imgsize:200px;
$main-color: #002e49;
.mb-1{
  margin-bottom:1rem;
}
.print{
  position: fixed;
  top: 25px;
  right: 20vh;
  height: 70px;
  width: 70px;
  background-color: #25dac1;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.container { 
  background: #5e5e5e ;
  .cv {
    background: #ffffff;
    min-height: 29.7cm;
    min-width: 23cm;
    max-width: 23cm;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .left {
      width: 30%;
      display: flex;
      flex-direction: column;
      background: $main-color;

      .image{
        // background: rgb(151, 151, 216);
        height: $imgsize;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        width: 100%;
        img{
          height: $imgsize;
          border-radius: 10px;
        }
      }
      .about, .contact,.interest,.hobies{
        padding : 1rem 1rem ;
        h1{
          border-bottom: 2px solid rgb(196, 196, 196) ;
          width: fit-content;
          text-transform: uppercase;
        }
        ul{
          margin-top: 1rem;
          li{
            margin: 1rem 0;
            font-weight: 700;
            word-wrap: break-word;  
            span{
              color: #fff;
              .icon{
                margin-bottom: -5px;
                font-size: 20px;
              }
            }
          }
        }
      }
      .about{
        ul{
          li{
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
      .contact{
        >ul{
          >li{
            font-size: 12px;
            line-height: 22px;
          }
        }
      }
      .interest{
        >ul{
          display: flex;
          flex-wrap: wrap;
          padding-left: 20px;
          li{
            margin: 5px 0;
            width: 50%;
            span{
              margin: 1rem 0;
              font-weight: normal;
            }

          }
        }
      }
      .hobies{
        ul{
          display: flex;
          flex-wrap: wrap;
          padding-left: 20px;
          li{
            font-size: 40px;
            width: 50%;
            margin: 0;
            
          }
        }
      }
    }
    .right {
      width: 70%;
      display: flex;
      flex-direction: column;
      // background: #462a2a;
      padding-right: 1rem;

      .name{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        font-size: 70px;
        color: #000;
        text-align: right;
        font-weight: 300;
        font-family: Arial, Helvetica, sans-serif;
        .line{
          height: 8px;
          margin-top: 20px;
          background: $main-color;
          width: 50%;
        }
      }
      .career,.personal-info,.exprience,.languange,.skills{
        margin:1.2rem 0 0 20px;
        h1{
          border-bottom: 2px solid #000 ;
          width: fit-content;
          font-weight: 600;
          text-transform: uppercase;
          color: #000;
        }
        .text{
          word-wrap: break-word;
          color: #000;
          margin: 10px;
        }
      }
      .personal-info{
        display: flex;
        justify-content: space-between;
        .education{
          .univercity{
            .degree-name{
              font-size: 1.2rem;
              color: #000;
            }
            .profetion{
              color: #000;
              margin-left: 1rem;
            }
            .uni-name{
              color: #000;
              margin:0.5rem 0 1rem  1rem ;
              font-style: italic;
            }
          }
        }
        .tecnical{
          >ul{
            >li{
              font-size: 16px;
              color: #000;
              margin: 1rem;
            }
          }
        }
      }
      .exprience{
        .job-container{
          padding-top: 1rem;
          margin-bottom: 1rem;
          .job-title{
            font-weight: 600;
            color: #000;
            font-size: 18px;
            margin-bottom: 0.5rem;
          }
          .job-time{
            color: #616161;
            font-style: italic;
          }
          >ul{
            >li{
              margin-bottom: 0.2rem;
              color: #000;
            }
          }
        }
      }
      .languange{
        >ul{
          >li{
            display: flex;
            padding: 0.5rem 2rem 0.5rem 0;
            h3{
              width: 50%;
              color: #000;
              text-align: start;
            }
          }
        }
      }
      .skills{
        >ul{
          >li{
            display: flex;
            justify-content: space-between;
            div:first-child{
              color: #000;
            }
            div:last-child{
              .dot{
                color: #000;
              }
              .dotgray{
                color: #6b6b6b;
              }
            }
          }
        }
      }
    }
  }
}
